<template>
    <div class="container">
        <h1>{{ $t('Components.Shop.StartSlider.Title') }}</h1>
        <h1>Allgemeine Geschäftsbedingungen</h1>
        <h2>§ 1 Geltungsbereich, Allgemeines, Teilnehmerkreis, Vertragssprache</h2>
        <p>(1) Die vorliegenden Allgemeinen Geschäftsbedingungen (AGB) finden Anwendung auf die zwischen Ihnen (nachfolgend „Teilnehmer“) und uns, der Mystery House (Hauptstrasse 5, 27419 Tiste, UST-ID Nr.: DE316310626 – nachfolgend „Das Geheimnis von Burg Sittensen“) vertreten durch die Geschäftsführer Michael Schulze und Matthias Klindworth (Impressum) über die Webseite unter https://das-geheimnis-von-burg-sittensen.de/ geschlossenen Vertrag zur Teilnahme an einem Online-Spiel in einem Online-Escape-Room.</p>
        <p>(2) Mystery House betreibt einen Online-Escape-Room, der ein Online-Spiel umfasst, in dem die Teilnehmer die Aufgabe haben, sich durch Lösung mehrerer Rätsel durch das Spiel zu spielen (nachfolgend „Online-Escape-Game“).<br><br>Für Personen unter 12 (zwölf) Jahren oder für Personen mit einer Nervenerkrankung ist die Teilnahme nicht empfohlen. Minderjährige Personen ab 12 (zwölf) Jahren ist die Teilnahme ausschließlich nur mit Zustimmung der Eltern oder eines Erziehungsberechtigten erlaubt.</p>
        <p>(3) Das Dienstleistungsangebot von Mystery House richtet sich gleichermaßen an Verbraucher und Unternehmer.<br><br>Für Zwecke dieser Allgemeinen Geschäftsbedingungen,<br><br>(a) ist ein Verbraucher jede natürliche Person, die den Vertrag zu einem Zweck abschließt, der weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann (§ 13 BGB) und<br><br>b) ist ein Unternehmer eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss des Vertrages in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt (§ 14 Absatz 1 BGB).</p>
        <p>(4) Geschäftsbedingungen des Teilnehmers finden keine Anwendung, auch wenn Mystery House ihrer Geltung im Einzelfall nicht gesondert widersprechen.</p>
        <p>(5) Die für den Vertragsschluss zur Verfügung stehende Sprache ist ausschließlich Deutsch. Übersetzungen dieser Geschäftsbedingungen in andere Sprachen dienen lediglich zu Ihrer Information. Bei etwaigen Unterschieden zwischen den Sprachfassungen hat der deutsche Text Vorrang.</p>
        <h2>§ 2 Online-Escape-Game/Pflichten des Teilnehmers</h2>
        <p>(1) Das Online-Escape-Game umfasst einen Zeitraum von 60-120 Minuten Spielzeit. Während der Spielzeit haben die Teilnehmer die Gelegenheit, die Rätsel im Online-Escape-Game über die Webseite von Das Geheimnis von Burg Sittensen zu erkunden und zu lösen.</p>
        <p>(2) Mystery House schuldet dabei keinen bestimmten Erfolg. Das Spiel ist beendet, wenn die Teilnehmer alle Rätsel des Online-Escape-Games gelöst haben. Haben die Teilnehmer innerhalb der Spielzeit das Online-Escape-Game nicht gelöst, können die verbleibenden Rätsel jederzeit nach Spielbeginn weiter gelöst werden.</p>
        <p>(3) Mystery House behält sich das Recht vor, einzelne Elemente des Online-Escape-Games aus technischen, betrieblichen oder anderen Gründen ohne vorherige Benachrichtigung zu ändern, zu schließen oder zu entfernen.</p>
        <p>(4) Für den Fall, dass der Teilnehmer zwar die Spielregeln zur Kenntnis genommen und akzeptiert hat, diese jedoch nicht beachtet oder nicht einhält und sich selbst oder Dritte schädigt, übernimmt Mystery House keine Haftung.</p>
        <p>(5) Der Teilnehmer ist zur Einhaltung der vor Beginn des Online-Escape-Games bekanntgegebenen Spielregeln verpflichtet.</p>
        <h2>§ 3 Vertragsschluss/Terminvereinbarung</h2>
        <p>(1) Die Darstellung der Dienstleistung auf der Webseite von Mystery House stellt kein rechtlich bindendes Angebot, sondern eine Aufforderung zur Bestellung dar.</p>
        <p>(2) Teilnehmer können über die Webseite von Mystery House die Teilnahme am Online-Escape-Game buchen. Die Buchung erfolgt unter https://das-geheimnis-von-burg-sittensen.de/ /. Am Ende des Buchungsvorgangs wird der Teilnehmer um Bestätigung ersucht, dass er die Allgemeinen Geschäftsbedingungen von Mystery House gelesen hat und diese akzeptiert.</p>
        <p>(3) Der Ablauf einer Buchung über die Webseite von Mystery House (und Partnerseiten) erfolgt in den folgenden Schritten:</p><ul><li>Auswahl des Escape Games</li><li>Auswahl und Autorisierung der Zahlungsmethode (PayPal)</li><li>Klick auf den „Zahlungspflichtig bestellen-Button“</li></ul><p>Durch Anklicken des „Zahlungspflichtig bestellen“ bzw. „Kaufen“-Buttons im letzten Schritt des Buchungsprozesses geben Sie ein verbindliches Angebot der Buchung der Dienstleistung von Mystery House ab. Unmittelbar nach Absenden der Buchung erhält der Teilnehmer eine Buchungsbestätigung (in der jeweils ein Access Code zugewiesen wird), die die Annahme des Vertragsangebots darstellt, so dass ein Vertrag zwischen dem Teilnehmer und Mystery House zustande kommt.</p>
        <p>Die in der Buchungsbestätigung zugewiesene Bestellnummer dient als Nachweis für die Buchung des jeweiligen Spiels. Der Teilnehmer muss diese zusammen mit dem Zugangsode zur Anmeldung am Online-System nutzen, um Zugriff auf das Online-Spiel zu erhalten.</p>
        <p>Jede Buchungsbestätigung per E-Mail ist vom Teilnehmer unmittelbar nach Zugang auf Richtigkeit zu überprüfen. Falls Informationen auf der Bestätigung falsch oder unvollständig erscheinen, muss der Teilnehmer unmittelbar Kontakt mit Mystery House über info@mystery-house.de aufnehmen, da eine Änderung zu einem späteren Zeitpunkt eventuell nicht mehr möglich ist.</p>
        <p>Einmal erworbene Buchungen können nicht in Verbindung mit anderen Angeboten, einer Aktion oder einem Gutschein verwendet bzw. gegen Bargeld eingetauscht werden. Eine Buchung ist nur bei dem jeweils gebuchten Escape-Game einlösbar.<br>Werden Gutscheine über E-Mail, telefonische Anfrage oder vor Ort gekauft, haben die Teilnehmer die Möglichkeit, die Gutscheine selbst auszudrucken (Versand per E-Mail).</p>
        <p>(4) Für den Umfang der Dienstleistung und seiner Abwicklung ist ausschließlich die vom Teilnehmer gebuchte Dienstleistung maßgebend.<br>Das vom Teilnehmer gebuchte Online-Escape-Game kann nur innerhalb eines Zeitraums von einem Jahr, beginnend ab der Buchung, durchgeführt werden. Nach Ablauf dieses Zeitraums ist die Durchführung des Online-Escape-Games nicht mehr möglich und die Zugangsdaten verfallen insoweit.</p>
        <p>(5) Die Vertragsbestimmungen mit Angaben zu den vereinbarten Dienstleistungen einschließlich dieser Allgemeinen Geschäftsbedingungen sowie der Widerrufsbelehrung (soweit ein sog. Fernabsatzvertrag vorliegt) werden dem Teilnehmer per E-Mail mit Annahme des Vertragsangebotes bzw. mit der Benachrichtigung hierüber zugesandt. Eine Speicherung der Vertragsbestimmungen durch uns erfolgt nicht.</p>
        <p>(6) Jeder Teilnehmer, der Verbraucher ist, ist berechtigt, das Angebot nach Maßgabe der besonderen Widerrufsbelehrung, die ihm im Rahmen der Bestellung auf unserer Website mitgeteilt wird, zu widerrufen.</p>
        <h2>§ 4 Vertragsdauer/Umbuchen von Terminen</h2>
        <p>(1) Die Dauer des Vertrags sowie der Zeitpunkt der Leistung ergeben sich aus den spezifischen Vereinbarungen zwischen Mystery House und dem Teilnehmer.</p>
        <p>(2) Das Recht zur Kündigung des Vertrags aus wichtigem Grund bleibt hiervon unberührt.</p>
        <h2>§ 5 Behinderung/Höhere Gewalt</h2>
        <p>(1) Sieht sich Mystery House in der Durchführung eines Auftrags durch Umstände gleich welcher Art behindert, so wird Mystery House dies dem Teilnehmer rechtzeitig schriftlich mitteilen. Sind die behindernden Umstände von Mystery House nicht zu vertreten, so werden sich die Vertragspartner über eine angemessene Verschiebung der vereinbarten Dienstleistung verständigen. Unterbleibt die rechtzeitige, schriftliche Mitteilung, so kann sich Mystery House später auf diese Umstände nicht berufen.<br><br>Sollte keine Einigung zwischen Mystery House und dem Teilnehmer zustande kommen, ist der Teilnehmer zum Rücktritt vom Vertrag berechtigt und ihm werden bereits geleistete Zahlungen erstattet.</p>
        <p>(2) In Fällen höherer Gewalt ist die hiervon betroffene Vertragspartei für die Dauer und im Umfang der Auswirkung von der Verpflichtung zur Leistung befreit.<br><br>Höhere Gewalt ist jedes außerhalb des Einflussbereichs der jeweiligen Vertragspartei liegende Ereignis, durch das sie ganz oder teilweise an der Erfüllung ihrer Verpflichtungen gehindert wird, einschließlich Feuerschäden, Überschwemmungen, Streiks und rechtmäßiger Aussperrungen sowie nicht von ihr verschuldeter Betriebsstörungen oder behördlicher Verfügungen.<br><br>Die betroffene Vertragspartei wird der anderen Vertragspartei unverzüglich den Eintritt sowie den Wegfall der höheren Gewalt anzeigen und sich nach besten Kräften bemühen, die höhere Gewalt zu beheben und in ihren Auswirkungen soweit wie möglich zu beschränken.<br><br>Die Vertragsparteien werden sich bei Eintritt höherer Gewalt über das weitere Vorgehen abstimmen und festlegen, ob nach ihrer Beendigung die während dieser Zeit nicht durchgeführte Leistungen nachgeliefert werden sollen. Ungeachtet dessen ist jede Vertragspartei berechtigt, von den hiervon betroffenen Bestellungen zurückzutreten, wenn die höhere Gewalt mehr als vier Wochen seit dem vereinbarten Leistungsdatum andauert. Das Recht jeder Vertragspartei, im Falle länger andauernder höherer Gewalt den Vertrag aus wichtigem Grund zu kündigen, bleibt unberührt.</p>
        <h2>§ 6 Preise, Zahlungsbedingungen, Aufrechnungsverbot</h2>
        <p>(1) Es gelten die in Anzeigen und/oder Internetseiten von Mystery House angegebenen, jeweils aktuellen Preise. Alle Preisangaben sind in Euro und inklusive Umsatzsteuer.</p>
        <p>(2) Die Zahlung der vom Teilnehmer gebuchten Dienstleistung erfolgt über unseren Zahlungsdienstleister PayPal.</p>
        <p>(3) Dem Teilnehmer steht kein Aufrechnungs- oder Zurückbehaltungsrecht zu, soweit nicht die Gegenforderung unbestritten oder rechtskräftig festgestellt ist.</p>
        <h2>§ 7 Widerrufsrecht</h2>
        <p>Bei außerhalb von Geschäftsräumen geschlossenen Verträgen und bei Fernabsatzverträgen steht Ihnen als Verbraucher ein Widerrufsrecht zu.</p>
        <h2>Widerrufsrecht</h2>
        <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.<br><br>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.<br><br>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns<br>Mystery House<br>Hauptstrasse 5<br>27419 Tiste,<br>E-Mail: info@mystery-house.de<br><br>mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.<br><br>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
        <h2>Folgen des Widerrufs</h2>
        <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
        <p>Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</p>
        <h2>Besondere Hinweise</h2>
        <p>Das Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von Dienstleistungen auch dann, wenn der Unternehmer die Dienstleistung vollständig erbracht hat und mit der Ausführung der Dienstleistung erst begonnen hat, nachdem der Verbraucher</p>
        <ul><li>ausdrücklich zugestimmt hat, dass der Unternehmer mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt und</li><li>seine Kenntnis davon bestätigt hat, dass er durch seine Zustimmung mit Beginn der Ausführung des Vertrags sein Widerrufsrecht verliert.</li></ul>
        <h2>Ende der Widerrufsbelehrung</h2>
        <h2>§ 8 Verschwiegenheit</h2>
        <p>(1) Alle zwischen den Vertragsparteien im Zusammenhang mit dem Vertrag und dessen Durchführung ausgetauschten Informationen und Daten sind von den Vertragsparteien vertraulich zu behandeln. Dies bezieht sich auch auf Informationen, die vor dem Vertragsschluss im Rahmen der Vertragsanbahnung ausgetauscht wurden.</p>
        <p>(2) Eine Weitergabe von diesen Informationen und Daten an Dritte oder eine Einsichtnahme durch Dritte ist nur dann zulässig, wenn dies für die Erfüllung des Vertragszwecks erforderlich ist, aufgrund von Rechtsvorschriften erlaubt oder mit Einwilligung jeweils beider Vertragspartner erfolgt.</p>
        <p>(3) Vorstehende Verpflichtungen gelten nicht für Informationen,<br>a) die dem Empfänger vor der Vertragsanbahnung nachweislich bereits bekannt waren oder danach von dritter Seite bekannt werden, ohne dass dadurch eine Vertraulichkeitsvereinbarung, gesetzliche Vorschriften oder behördliche Anordnungen verletzt werden;<br>b) die bei Abschluss des Vertrags öffentlich bekannt sind oder danach öffentlich bekannt gemacht werden, soweit dies nicht auf einer Verletzung des Vertrags mit dem Teilnehmer beruht;<br>c) die aufgrund gesetzlicher Verpflichtungen oder auf Anordnung eines Gerichtes oder einer Behörde offengelegt werden müssen.<br>Soweit zulässig und möglich wird der zur Offenlegung verpflichtete Empfänger die andere Vertragspartei vorab unterrichten und ihr Gelegenheit geben, gegen die Offenlegung vorzugehen.</p>
        <h2>§ 9 Datenschutz</h2>
        <p>(1) Zu den Qualitätsansprüchen von Mystery House gehört es, verantwortungsbewusst mit den persönlichen Daten der Teilnehmer (diese Daten werden nachfolgend „personenbezogene Daten“ genannt) umzugehen. Die sich aus dem Vertragsverhältnis ergebenden personenbezogenen Daten werden von Mystery House daher nur erhoben, gespeichert und verarbeitet, soweit dies zur vertragsgemäßen Leistungserbringung erforderlich und durch gesetzliche Vorschriften erlaubt, oder vom Gesetzgeber angeordnet ist. Mystery House wird die personenbezogenen Daten der Teilnehmer vertraulich sowie entsprechend den Bestimmungen des geltenden Datenschutzrechts behandeln und nicht an Dritte weitergeben.</p>
        <p>(2) Hierüber hinaus verwendet Mystery House personenbezogene Daten der Teilnehmer nur, soweit der Teilnehmer hierzu ausdrücklich eingewilligt hat. Eine vom Teilnehmer erteilte Einwilligung kann er jederzeit widerrufen.</p>
        <p>(3) Dem Teilnehmer ist bekannt, dass zur Durchführung vorvertraglicher Maßnahmen und Erfüllung des Vertragsverhältnisses die Erhebung, Verarbeitung und Nutzung auf Basis von Art. 6 Absatz 1 lit. b) DSGVO unter anderem von dessen Name, Verbraucher- bzw. Unternehmereigenschaft, Adresse, Geburtsdatum und Bankverbindung erforderlich sind.</p>
        <p>(4) Mystery House wird im Hinblick auf personenbezogene Daten des Teilnehmers die maßgeblichen gesetzlichen Bestimmungen, insbesondere die Datenschutz-Grundverordnung (DSGVO), wahren.</p>
        <p>(5) Im Übrigen verweist Mystery House auf seine <a href="/datenschutz">Datenschutzerklärung</a>.</p>
        <h2>§ 10 Schlussbestimmungen</h2>
        <p>(1) Der zwischen Mystery House und dem Teilnehmer bestehende Vertrag unterliegt vorbehaltlich zwingender internationalprivatrechtlicher Vorschriften dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechtsübereinkommens. Ist der Teilnehmer jedoch Verbraucher und hat er seinen gewöhnlichen Aufenthalt in einem anderen Staat, so bleibt ihm der Schutz nach den maßgeblichen Bestimmungen des Aufenthaltsstaats, von denen nicht durch Vereinbarung abgewichen werden darf, erhalten.</p>
        <p>(2) Ist der Teilnehmer Kaufmann im Sinne des § 1 Absatz 1 HGB, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen, so sind die Gerichte in Tostedt für alle Streitigkeiten aus oder im Zusammenhang mit dem betreffenden Vertragsverhältnis ausschließlich zuständig. In allen anderen Fällen kann Mystery House oder der Teilnehmer Klage vor jedem aufgrund gesetzlicher Vorschriften zuständigen Gericht erheben.</p>
        <p>(3) Mystery House weist den Teilnehmer darauf hin, dass er als Verbraucher neben dem ordentlichen Rechtsweg auch die Möglichkeit einer außergerichtlichen Beilegung von Streitigkeiten gemäß Verordnung (EU) Nr. 524/2013 hat. Einzelheiten dazu finden sich in Verordnung (EU) Nr. 524/2013 und unter der Internetadresse: https://ec.europa.eu/consumers/odr.</p>
        <p>Die E-Mail-Adresse von Mystery House lautet: info@mystery-house.de. Mystery House weist nach § 36 VSBG darauf hin, dass Mystery House nicht verpflichtet ist, an einem außergerichtlichen Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        <p>(4) Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein, so wird hierdurch der Vertrag im Übrigen nicht berührt.</p>
        <h3>November 2022</h3>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
.container{
    display: block;
    width: 80%;
    max-width: 800px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}
h1{
    margin-top: 30px;
    margin-bottom: 30px;
}
h3{ 
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
}
p{
    font-size: 20px;
    margin: 20px;
    text-align: left;
}
</style>
