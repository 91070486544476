<template>
    <footer>
        <div class="feed">
            <div class="links">
                <a :href="`https://www.mystery-house.de/${$i18n.locale}/impressum/`" target="_blank">{{
                    $t('Components.Shop.ShopFooter.Imprint')
                }}</a>
                <a :href="`https://www.mystery-house.de/${$i18n.locale}/datenschutzerklaerung`" target="_blank">{{
                    $t('Components.Shop.ShopFooter.Privacy')
                }}</a>
                <a :href="`https://www.mystery-house.de/${$i18n.locale}/agb-online-escape`" target="_blank">{{
                    $t('Components.Shop.ShopFooter.ToS')
                }}</a>
                <!-- <router-link :to="`/${$i18n.locale}/kontakt`">{{
                    $t('Components.Shop.ShopFooter.Contact')
                }}</router-link> -->
            </div>
            <div class="social">
                <a href="#" id="instagram">
                    <i class="fab fa-instagram"></i>
                </a>
                <a href="#" id="facebook">
                    <i class="fab fa-facebook"></i>
                </a>
            </div>
        </div>
        <div class="cp">
            <a target="_blank" href="https://mystery-house.de">&#169; Mystery House</a>
            <div class="standorte">
                <p>{{ $t(`Views.Shop.Standorte.Title`) }}</p>
                <a target="_blank" href="https://mystery-house.de/bremen">Bremen</a>
                <a target="_blank" href="https://mystery-house.de/hamburg">Hamburg</a>
                <a target="_blank" href="https://mystery-house.de/flensburg">Flensburg</a>
            </div>
        </div>
    </footer>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import LanguageChanger from '@/components/LanguageChanger.vue'

export default defineComponent({
    components: {
        LanguageChanger,
    },
    setup() {},
    mounted() {
        let fontawesomeScript = document.createElement('script')
        fontawesomeScript.setAttribute(
            'src',
            'https://kit.fontawesome.com/f1b622df2b.js'
        )
        fontawesomeScript.setAttribute('crossorigin', 'anonymous')
        document.head.appendChild(fontawesomeScript)
    },
})
</script>

<style scoped>
.feed {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background: var(--base-black);
    padding: 12px 10% 12px 10%;
    flex-wrap: wrap;
}
.links{
    display: flex;
    gap: 20px;
}
.social{
    display: flex;
    gap: 20px;
}
.links a {
    font-size: 14px;
    color: var(--base-white);
    text-decoration: none;
    text-align: center;
    padding: 10px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
.social a {
    font-size: 25px;
    color: var(--base-white);
    margin: auto;
}
.social a i {
    margin-top: 5px;
}
a:hover {
    color: rgb(199, 199, 199);
}
.cp{
    background: var(--main-bg-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 10% 14px 10%;
}
.cp a{
    font-size: 14px;
    color: var(--base-white);
    text-decoration: none;
    text-align: center;
    margin: 0 10px 0 10px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
.standorte{
    display: flex;
    flex-direction: row;
}
.standorte a{
    margin: 0 0 0 20px;
}
.standorte p{
    color: #fff;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}
@media screen and (max-width: 600px) {
    .feed {
        flex-direction: column;
    }
    .feed {
        padding-left: 5%;
        padding-right: 5%;
        height: 100%;
    }
    .links{
        width: fit-content;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
}
    .social{
        display: flex;
        gap: 20px;
    }
    .cp{
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }
}
</style>
