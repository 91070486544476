export default {
  "Utilities": {
    "Alerts": {
      "GamePaused": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Spiel ist pausiert"])}
      },
      "GameResumed": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Spiel wird nun fortgesetzt"])}
      },
      "GameFinished": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glückwunsch"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Spiel wurde beendet"])}
      },
      "PlayerSolvedRiddle": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des Rätsels Lösung"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Spieler hat das Rätsel gelöst. Gleich geht es weiter."])},
        "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum nächsten Rätsel"])}
      },
      "TeamChangedPage": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Team schreitet voran"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Team hat die aktuelle Seite verlassen, willst du mitgehen und im Team weiterspielen oder willst du alleine weiterspielen?"])}
      },
      "Hint": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Hinweis"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Spieler hat sich einen Hinweis anzeigen lassen. Willst du diesen auch anzeigen lassen?"])}
      },
      "ConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "DenyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])}
    },
    "Toasts": {
      "AnswerRightButNotFinal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist auf dem richtigen Weg"])},
      "AnswerWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht richtig"])},
      "TeamChangedPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Team schreitet voran"])},
      "EmailNichtKorrekt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adressen stimmen nicht überein"])}
    },
    "Riddles": {
      "DescriptionReadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Rätsel"])}
    },
    "Fragments": {
      "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einpacken und weiter"])}
    }
  },
  "Components": {
    "Game": {
      "Kirche": {
        
      },
      "Schloss": {
        "KugelGif": {
          
        },
        "SchiebeTafel": {
          
        },
        "Soundboard": {
          
        },
        "WappenRiddle": {
          
        }
      },
      "AlertEventListener": {
        
      },
      "DisableInput": {
        
      },
      "GameEventListener": {
        
      },
      "GameFooter": {
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["®Mystery House"])}
      },
      "GameHeader": {
        
      },
      "Hint": {
        "HintValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten Strafe"])},
        "ShowHint": {
          "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis zeigen"])},
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösung zeigen"])}
        },
        "ConfirmUse": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bist du dir sich sicher, dass Sie ", _interpolate(_named("timeValue")), " Minuten Strafe für den Hinweis wollen?"])}
      },
      "HintWrapper": {
        
      },
      "Inventory": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventar"])}
      },
      "InventoryButton": {
        
      },
      "InventoryItem": {
        
      },
      "JigsawPopup": {
        
      },
      "PlayerVerifier": {
        
      },
      "Popup": {
        
      },
      "PopupContainer": {
        
      },
      "RiddleDescriptionSound": {
        
      },
      "RiddleDescriptionVideo": {
        
      },
      "RiddleInput": {
        "Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösen"])},
        "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösung"])}
      },
      "SwitchButton": {
        
      },
      "SyncButton": {
        "IsSynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du spielst mit deinem Team"])},
        "NotSynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du spielst alleine"])}
      },
      "Timer": {
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstrichene Zeit"])}
      },
      "ToastEventListener": {
        
      }
    },
    "Shop": {
      "InfoSection": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Escape Room"])},
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr könnt unser Online Escape Game direkt im Browser zu Hause spielen und müsst keine App herunterladen oder Spielmaterial drucken. Die Rätsel können alleine oder mit beliebig vielen Mitspielern gelöst werden. Auch wenn eure Spielpartner räumlich getrennt sind, könnt ihr dennoch gemeinsam von überall online mit dem gleichen Zugangscode spielen. Auf was wartet ihr noch? Schnappt euch ein paar Freunde und los geht der Rätsel-Spaß. Aber kein Angst, solltet ihr nicht weiter wissen gibt es Hinweise für jedes Rätsel."])},
        "FeatureTitles": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.99€ pro Gruppe"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis zu 6 Geräte"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Schnitt 1,5 Stunden"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Spielmaterial"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinschaftsspiel"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofort Startklar"])},
          
        ],
        "FeatureTexts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spannender Rätselspaß mit liebevollen Details und abwechslungsreichen Rätseln."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmal als Gruppe zahlen und mit beliebig vielen Mitspielern gleichzeitig von verschiedenen Orten mit bis zu 6 Geräten spielen."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielzeit variiert je nach Können und Gruppengröße. Ohne Zeitlimit! Empfohlen ab 14 Jahren."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetfähiges Gerät ausreichend (am besten PC, Laptop oder Tablet), sowie Zettel & Stift."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindet euch per Telefon oder (Video-)Chats z.B. Skype, WhatsApp oder Zoom. Auch als Teamevent geeignet mit unserem Team-Modus!"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Zahlen sofort spielen. Zugangscode ist unbeschränkt lange gültig und berechtigt zum einmaligen Spielen."])},
          
        ]
      },
      "Item": {
        "Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
        "Gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist das Spiel ein Geschenk?"])}
      },
      "ItemPrice": {
        
      },
      "Nav": {
        "HomeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "LoginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
      },
      "ProductSection": {
        "Titles": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Geheimnis von Burg Sittensen jetzt online spielen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Bewertungen*"])},
          
        ],
        "Texts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als ein Sammler an dich herantritt und dir ein Kaufangebot für euer altes Familiengemälde unterbreitet, begibst du dich auf die Suche nach deinem Vorfahren, der in Diensten der Schulten stand. Ist das Bild wirklich von einer Schwester der Schulten gemalt worden? Wie ist das Bild in euren Besitz gelangt? Kannst du das Geheimnis von Burg Sittensen lösen?"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast einen Rabattgutschein ? Hier kannst du ihn einlösen"])},
          
        ]
      },
      "ShopFooter": {
        "Imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
        "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
        "ToS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGB"])},
        "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
      },
      "ShopHeader": {
        
      },
      "StartSlider": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Geheimnis von Burg Sittensen"])}
      }
    },
    "DebugBar": {
      
    },
    "LanguageChanger": {
      
    }
  },
  "Views": {
    "Game": {
      "Kirche": {
        "Altar": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gott erschuf die Welt in sechs Tagen, aller guten Dinge sind drei auch wenn die Kirche hier eher von der Dreifaltigkeit spricht, die Woche hat sieben Tage, wobei die sieben auch in der Kirche eine wichtige Rolle spielt: 7 Laster, 7 Tugenden und 7 letzte Worte am Kreuz. Wieder erlangen wir die Erkenntnis, alles im Universum ist Mathematik und wie so oft liegt des Rätsels Lösung im Verborgenen. Das dritte Rätsel in der St.-Dionysius-Kirche fordert daher noch einmal eure ganze Aufmerksamkeit, ein scharfes Auge und absolute Konzentration. Alea iacta est."])}
        },
        "AltarRueckseite": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Und wieder liegt des Rätsels Lösung im Verborgenen. Auf der Suche nach der Wahrheit ist es auch oft wichtig, einfach mal die Perspektive zu wechseln. Das wußten tatsächlich auch schon die alten Römer."])}
        },
        "Aposteldenkmal": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mathematik hat euch den Weg in die Kirche geebnet und Mathematik begleitet euch auch auf einer himmlischen Sinfonie durch die altehrwürdige St.-Dionysius-Kirche. Ob ihr gläubig seid oder nicht, ist nicht wichtig, doch hier zählt das Wort Gottes. Haltet es daher wie die bibelfesten Brüder und lobet dem Herrn, dann wird er euch ganz gewiss den richtigen Weg weisen."])}
        },
        "Fragment": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast ein Fragment der Urkunde gefunden."])}
        },
        "Steintafel": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast den richtigen Weg gewählt. Doch leider ist die Tür der Kirche verschlossen. Gibt es vielleicht noch einen anderen Eingang oder gar eine Geheimtür oder versteckte Hinweise? Irgendwie muss es doch möglich sein in die St.-Dionysius-Kirche zu kommen! Mit Logik, dem richtigen Werkzeug im Gepäck und einem scharfen Blick werdet ihr die Lösung finden. Und denkt immer daran: letzten Endes ist doch alles irgendwie immer Mathematik und sogar die Kirche spricht von göttlichen Zeichen und Zahlen."])}
        },
        "Taufbecken": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für viele Menschen sind Kirchen Orte der Ruhe und der Kraft; geschmückt mit göttlichen und manchmal auch mit geheimnisvollen Symbolen und Heiligen-Bildern. In früheren Zeiten sollten Kirchen aber auch Reichtum und Macht präsentieren. Nicht selten wurden durch die Kirche Geschichte und Geschicke gelenkt. Welche Bedeutung hat eine Kirche für euch? Ist sie ein Ort der Begegnungen? Bietet sie Trost? Steht sie für Geburt, Leben und Tod? Welche Geschichte oder gar Mythen erzählen uns die Bilder und Symbole und welche Kraft und vielleicht sogar Faszination üben sie auf uns aus? Verfolgt eine Kirche Ideale wie Freiheit, Gleichheit, Brüderlichkeit, Toleranz und Humanität? Mit einem ethischen und philosophischen Ansatz lässt sich vielleicht auch dieser göttliche Code knacken."])}
        },
        "Wegweiser": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist kühl und still im Kirchpark der alten St.-Dionysius-Kirche. Die Blätter der Bäume werfen Schatten auf den Weg, alles wirkt friedlich. Doch was ist hier nur passiert? Hier sollte uns doch der Weg gewiesen werden? Anscheinend will uns jemand von der Spurensuche abhalten - oder könnt ihr die Hinweise wieder zusammensetzen?"])}
        },
        "Einleitung": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Glück haben unsere Vorfahren ein Tagebuch geführt, welches uns zufällig in die Hände gefallen ist. Dank der geschichtsträchtigen und persönlichen Zeilen erhalten wir Einblicke in das Leben und die Arbeit. So erfahren wir auch: Zu den Verwalter-Aufgaben gehörte es, sich um die Instandhaltung der Kirche und hier insbesondere um die Schmückung und Pflege des historischen Altars, mit dem Bildnis der vier Evangelisten, knorpelverzierten Säulen und goldenen Engeln. Doch in den heiligen Hallen gibt es noch mehr zu entdecken!"])}
        }
      },
      "Obelisk": {
        "Einleitung": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Blick in das alte Tagebuch der Verwalter-Familie gibt Aufschluss über das Leben am Hof aber eben insbesondere auch über die Pflichten und vielfältigen Aufgaben unserer Vorfahren. So erfahren wir: Mindestens einmal pro Monat führte der Weg auch zur Gartenpflege an der Erbbegräbnisstätte. Unter hohen Laubbäumen, zwischen Wiesen und Feldern und einem idyllisch plätschernden Bach haben die einstigen Herrschaften ihre letzte Ruhestätte, unter schweren Steinplatten und einem aufragenden, rätselhaften Obelisken gefunden. Unter ihnen wurden vielleicht auch einige Mysterien vergraben."])}
        },
        "AlexanderSchulte": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der richtigen Strategie löst du das Geheimnis des Obelisken. Die Lösung bietet das königliche Spiel auf den Gräbern. Kannst du mit Hilfe der Geschichte des „Entflohenen Pferdes“ auf dem richtigen Weg über die Gräber reiten?"])}
        },
        "Fragment": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast ein Fragment der Urkunde gefunden."])}
        },
        "Obelisk": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Geheimnis des Obelisken ist noch nicht ganz gelüftet. Erneut erkennen wir, Mathematik löst viele Rätsel und wie so oft spielen heilige Zahlen und in diesem Fall sogar auch ein antiker griechischer Philosoph eine wichtige Rolle."])}
        },
        "OttoSchulte": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elle, Fuß, Stein- und Hammerwurf oder auch Zoll und Spanne beschrieben in früheren Zeiten die Entfernung und Größe. Doch wie groß ist ist der Text im Verhältnis zur Maßeinheit?"])}
        },
        "SophieSchulte": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In alle Himmelsrichtungen verteilt sind Noten oft der Schlüssel zum Erfolg. Das solltest du unbedingt im Kopf haben, wenn du das Rätsel um das Grab von Sophie lösen willst."])}
        }
      },
      "Schloss": {
        "Einleitung": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Erzählungen und den historischen Unterlagen wissen wir, die einstigen Verwalter vom Schloss lebten natürlich immer auf dem Schlossgelände. Im Gegensatz zu den adligen Besitzern, die nur zeitweise auf dem Areal anwesend waren. Z.B. zu illustren Jagdausflügen oder in der kurzweiligen Sommerfrische. Die Familie unserer Vorfahren war dafür verantwortlich Schloss, Park, Ländereien, Ställe und Ausstattung in Schuss zu halten. Besonders auf das Schloss mußte dabei ein spezielles Augenmerk gelegt werden. Noch heute soll es viele Geheimnisse hinter seinen Mauern verbergen."])}
        },
        "Adler": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einst erzählte dieser Ort von Rittern und Fürsten, von prächtigen Zusammenkünften und adligen Gutsherren. Im Gutspark erinnern Statuen und Büsten an diese längst vergangene Zeit, an Stärke sowie Macht und sie laden euch ein, doch einfach mal genauer hinzuschauen."])}
        },
        "Eingang": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An diesem Ort fügt sich das halbe Rätsel zu einem Ganzen und das antike Universum der Götter um Krieg, Liebe, Herrschaft, Handel, Himmel, Wasser und Erde, beginnt sich zu drehen. Doch wer geht hinter wem? Viele Geheimnisse werden oft in Büchern bewahrt."])}
        },
        "Eiskeller": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gehts nun an einen unterirdischen, kalten, leeren und fast vergessenen Ort. Die Kälte lässt euch zittern oder sind es wieder einmal die Geheimnisse die euch erschauern lassen. Ist der Raum wirklich leer oder könnt ihr es nur nicht sehen? Es lohnt sich auch hier zweimal hinzuschauen, wobei andere dieses Rätsel auch blind ertasten könnten, um danach einfach mal rohe Kräfte walten zu lassen, damit diese Hälfte des Rätsels gelöst ist."])}
        },
        "Fragment": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast ein Fragment der Urkunde gefunden."])}
        },
        "Galerie": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Rittergut hat seine Türen für dich geöffnet. Du streifst durch die Räume auf der Suche nach Geschichten und Hinweisen. Pass auf, dass du den Überblick behältst und dich nicht in Geschichten, phantasievollen Bildern oder gar in den Räumen verirrst, wie ein verlorener Prinz. Kannst du das Rätsel um den verirrten Prinzen lösen und ihm und damit auch dir helfen?"])}
        },
        "Kaminzimmer": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo einst die Flammen für Wärme gesorgt haben, verstecken sich heute Rätsel. Töne und Zeichen weisen den richtigen Weg und helfen dir die Lösung zu finden. So kannst du Hand anlegen und sehen was den Augen verborgen ist."])}
        },
        "Treppe": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länder, Städte, Dörfer, mächtige Familien und einflussreiche Adelshäuser zeigen ihre Zusammengehörigkeit und Stärke bunt, symbolträchtig, voller Stolz ab und zu tierisch und oft verschlüsselt. Auch das Rittergut in Burgsittensen verfügte einst über ein solches Erkennungsmerkmal und einen - aus heutiger Sicht - wohl fragwürdigen Leitspruch. Alles was du tun mußt: spreize die Flügel und entschlüssle die Symbolik an der richtigen Stelle."])}
        }
      },
      "Finished": {
        "Descriptions": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hurra, du hast alle Rätsel gelöst und den Brief als Echtheitszertifikat gefunden. Was möchtest du jetzt machen?"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadurch, dass das Bild als echt bestätigt wurde, hast du die Möglichkeit, es zu verkaufen."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da das Bild schon seit längerer Zeit in der Familienbesitz ist, willst du es nicht verkaufen."])},
          
        ],
        "Texts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Verkaufen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Behalten"])},
          
        ],
        "FinishButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel Beenden"])}
      },
      "Karte": {
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele Geschichten und Abenteuer beginnen mit dem Satz „es war einmal“ oder „es begab sich vor sehr langer Zeit“. Auch unser gemeinsames Abenteuer möchten wir mit einem dieser Sätze beginnen."])},
        "Text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["…denn es begab sich vor sehr langer Zeit, dass einer unserer Vorfahren das ehrenwerte Amt des Hofverwalters auf dem Klostergut in Burgsittensen ausgeübt hat. Aus Dankbarkeit für seine langjährige Treue, seinen unermüdlichen Einsatz und stets korrekte Ausübung seines Amtes, schenkte die damalige Gutsherrin und Künstlerin Auguste von Schulte (sie lebte von 1800 bis 1856) ein Portrait. So die Geschichte, die von einer Generation zur anderen überliefert wurde. Genau das gilt es nämlich nun zu beweisen, denn leider hat Auguste viele ihrer Bilder nicht signiert. Das besagte Portrait befindet sich in eurem Besitz, gemeinsam mit dem geheimnisvollen Tagebuch eures Ahnen. Ein namenhafter Kunstsammler möchte das Bild für einen hohen Betrag kaufen, wenn das Bild wirklich von Auguste von der Schulte gemalt wurde."])},
        "Text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird es euch gelingen die Echtheit des Portraits zu beweisen? Begebt euch auf eine spannende Spurensuche an den ehemaligen Wirkungsstätten Augustes und eures Ahnen - dem ehemaligen Hofverwalter."])}
      },
      "Tutorial": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die wichtigsten Erläuterungen zum Spiel"])},
        "Labels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Karte"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rätselbeschreibungen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popups"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventar"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
          
        ],
        "MiniLabels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oben links"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oben rechts"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirche [3]"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obelisk [4]"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schloss [5]"])},
          
        ],
        "Texts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe, mit Hinweisen zum Rätsel. Erläuterung um die Rätselbeschreibung erneut zu lesen. [1]"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiterfassung: Wie lange spielst du schon? [2]"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erläuterung der Symbole auf der Karte:"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Symbole sind klickbar."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So sieht eine Rätselbeschreibung aus."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dem Beschreibungsbutton oben links oder dem 'Zum Rätsel-Button' unten in der Mitte geht es zum Rätsel. [1]"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Rätsel haben eine Lösungsbox, bei diesen Rätseln wird ein Lösungswort oder Code erwartet. Einige haben diesen nicht, hier soll ein Rätsel auf der Seite gelöst werden. [2]"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf den Rätselseiten gibt es ein Haupt-Bild mit unterschiedlichen Objekten. [1]"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadurch öffnen sich Popups oder du interagierst mit der Seite."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die im Inventar vorhandenen Seiten lassen sich auch per Mausklick öffnen."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechts siehst du dein Inventar."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In jedem Kapitel steht eine Auswahl des Inventars zur Verfügung. Du musst bei den Rätseln herausfinden, welches die richtige Wahl ist."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir wünschen dir / euch spannenden Rätselspaß!"])},
          
        ]
      }
    },
    "Shop": {
      "Standorte": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Standorte: "])}
      },
      "AGB": {
        "Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe die "])},
        "Terms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" gelesen und stimme diesen zu. Mir ist bekannt, dass ich mit Registrierung mein Widerrufsrecht verliere."])}
      },
      "Bewerten": {
        "Labels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielzeit"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielzeit"])},
          
        ],
        "Buttons": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerten"])},
          
        ],
        "Headlines": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
          
        ]
      },
      "Datenschutz": {
        "P": [
          
        ]
      },
      "Empty": {
        
      },
      "Home": {
        
      },
      "Impressum": {
        
      },
      "Kontakt": {
        "Headlines": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
          
        ],
        "Labels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie schon eine Vorstellung?"])},
          
        ],
        "Buttons": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage Senden"])},
          
        ],
        "Descriptions": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind an einem eigenen Online-Escape-Raum interessiert? Dann hinterlassen Sie uns doch Ihre Kontaktdaten. Wir werden uns bei Ihnen melden."])},
          
        ]
      },
      "Login": {
        "Titles": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
          
        ],
        "Labels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Code"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Wiederholen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
          
        ],
        "Toasts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail nicht korrekt"])},
          
        ],
        "Alerts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestimmen Sie einen Team-Namen"])},
          
        ],
        "Buttons": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
          
        ],
        "Errors": {
          "E-Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mails stimmen nicht überein."])},
          "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle einen Nutzernamen um zu starten."])},
          "AGB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte akzeptiere die AGBs."])},
          "inValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Code ungültig."])},
          "Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Teamnamen angeben"])}
        }
      },
      "ZugangsCode": {
        "Labels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal ID"])},
          
        ],
        "Headlines": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code nicht erhalten?"])},
          
        ],
        "Descriptions": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie hier Ihre PayPal-ID ein und erhalten Sie Ihren Code erneut!"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Access-Code lautet"])},
          
        ],
        "Buttons": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code erhalten"])},
          
        ]
      }
    },
    "App": {
      "Handy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte kein Handy oder Tablet verwenden. Einige Rätsel lassen Sich nicht lösen."])}
    }
  },
  "Images": {
    "Schloss": {
      "Goetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/schloss/pages/page_eingang.jpg"])}
    }
  }
}