<template>
    <div class="container">
        <h1><strong>Impressum</strong></h1>
        <p><strong>Mystery House</strong><br>Hauptstrasse 5<br>DE-27419 Tiste</p>
        <p><strong>Geschäftsführung</strong><br>Michael Schulze<br>Matthias Klindworth</p>
        <p>Registergericht: Tostedt<br>Registernummer: HRB 206547</p>
        <p>Umsatzsteuer-Identifikationsnummer gemäß<br>§27a Umsatzsteuergesetz: DE316310626</p>
        <p>Inhaltlich verantwortlich gemäß § 55 abs. 2 RSTV:<br>Verantwortliche Person: Michael Schulze</p>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
.container{
    display: block;
    width: 80%;
    max-width: 800px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}
p{
    font-size: 20px;
    margin: 14px;
    text-align: left;
}
</style>
